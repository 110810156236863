<template>
  <div class="order-mock-form">
    <h1>Order Mock Form</h1>
    <form @submit.prevent="submitForm">
      <div class="form-row">
        <div class="form-group">
          <label for="name">Customer Name</label>
          <input type="text" id="name" v-model="name" required />
        </div>
        <div class="form-group">
          <label for="buyer_email">Customer Email</label>
          <input type="text" id="buyer_email" v-model="buyer_email" required />
        </div>
      </div>
      <div class="form-row">
        <div class="form-group">
          <label for="first_line">Address First Line</label>
          <input type="text" id="first_line" v-model="first_line" required />
        </div>
        <div class="form-group">
          <label for="second_line">Address Second Line</label>
          <input type="text" id="second_line" v-model="second_line" required />
        </div>
      </div>
      <div class="form-row">
        <div class="form-group">
          <label for="city">City</label>
          <input type="text" id="city" v-model="city" required />
        </div>
        <div class="form-group">
          <label for="state">State</label>
          <input type="text" id="state" v-model="state" required />
        </div>
      </div>
      <div class="form-row">
        <div class="form-group">
          <label for="zip">Zip</label>
          <input type="text" id="zip" v-model="zip" required />
        </div>
        <div class="form-group">
          <label for="country_iso">Country ISO</label>
          <input type="text" id="country_iso" v-model="country_iso" required />
        </div>
      </div>
      <div class="form-row">
        <div class="form-group">
          <label for="payment_method">Payment Method</label>
          <input type="text" id="payment_method" v-model="payment_method" required />
        </div>
        <div class="form-group">
          <label for="payment_email">Payment Email</label>
          <input type="text" id="payment_email" v-model="payment_email" required />
        </div>
      </div>
      <div class="form-row">
        <div class="form-group">
          <label for="message_from_payment">Message from Payment</label>
          <input type="text" id="message_from_payment" v-model="message_from_payment" required />
        </div>
        <div class="form-group">
          <label for="message_from_seller">Message from Seller</label>
          <input type="text" id="message_from_seller" v-model="message_from_seller" required />
        </div>
      </div>
      <div class="form-row">
        <div class="form-group">
          <label for="message_from_buyer">Message from Buyer</label>
          <input type="text" id="message_from_buyer" v-model="message_from_buyer" required />
        </div>
        <div class="form-group">
          <label for="is_shipped">Is Shipped</label>
          <select id="is_shipped" v-model="is_shipped" required>
            <option value="1">True</option>
            <option value="0">False</option>
          </select>
        </div>
      </div>
      <div class="form-row">
        <div class="form-group">
          <label for="is_paid">Is Paid</label>
          <select id="is_paid" v-model="is_paid" required>
            <option value="1">True</option>
            <option value="0">False</option>
          </select>
        </div>
        <div class="form-group">
          <label for="is_gift">Is Gift</label>
          <select id="is_gift" v-model="is_gift" required>
            <option value="1">True</option>
            <option value="0">False</option>
          </select>
        </div>
      </div>
      <div class="form-row">
        <div class="form-group">
          <label for="gift_message">Gift Message</label>
          <input type="text" id="gift_message" v-model="gift_message" required />
        </div>
        <div class="form-group">
          <label for="gift_sender">Gift Sender</label>
          <input type="text" id="gift_sender" v-model="gift_sender" required />
        </div>
      </div>
      <div class="form-row">
        <div class="form-group">
          <label for="status">Status</label>
          <select id="status" v-model="status" required>
            <option value="paid">Paid</option>
            <option value="completed">Completed</option>
          </select>
        </div>
      </div>
      <div class="form-row">
        <div class="form-group">
          <label for="product">Product</label>
          <v-combobox
            v-model="selectedProduct1.product"
            :items="productOptions"
            label="Select Products"
            item-text="key"
            item-value="id"
            clearable
            @update:search-input="searchProducts"
            required
          ></v-combobox>
          <input type="number" id="selectedProduct1.quantity" v-model="selectedProduct1.quantity" required />
        </div>
        <div class="form-group">
          <label for="product">Product</label>
          <v-combobox
            v-model="selectedProduct2.product"
            :items="productOptions"
            label="Select Products"
            item-text="key"
            item-value="id"
            clearable
            @update:search-input="searchProducts"
          ></v-combobox>
          <input type="number" id="selectedProduct2.quantity" v-model="selectedProduct2.quantity" />
        </div>
      </div>
      <div class="form-row">
        <div class="form-group">
          <label for="product">Product</label>
          <v-combobox
            v-model="selectedProduct3.product"
            :items="productOptions"
            label="Select Products"
            item-text="key"
            item-value="id"
            clearable
            @update:search-input="searchProducts"
          ></v-combobox>
          <input type="number" id="selectedProduct3.quantity" v-model="selectedProduct3.quantity" />
        </div>
        <div class="form-group">
          <label for="product">Product</label>
          <v-combobox
            v-model="selectedProduct4.product"
            :items="productOptions"
            label="Select Products"
            item-text="key"
            item-value="id"
            clearable
            @update:search-input="searchProducts"
          ></v-combobox>
          <input type="number" id="selectedProduct4.quantity" v-model="selectedProduct4.quantity" />
        </div>
      </div>
      <button type="submit">Submit</button>
    </form>
  <div style="border: 1px solid #ccc; padding: 10px; margin-top: 20px;">
    {{ this.result}}
  </div>
  </div>

</template>



<script>
import axios from 'axios';
import store from '@/store'

export default {
  data() {
    return {
      shop: null,
      name: 'John Doe',
      buyer_email: 'john.doe@example.com',
      first_line: '123 Main St',
      second_line: 'Apt 4B',
      city: 'New York',
      state: 'NY',
      zip: '10001',
      country_iso: 'US',
      payment_method: 'CC',
      payment_email: 'payment@example.com',
      message_from_payment: 'Payment received',
      message_from_seller: 'Thank you for your purchase',
      message_from_buyer: 'Please deliver between 9 AM and 5 PM',
      is_shipped: 0,
      is_paid: 1,
      is_gift: 0,
      gift_message: 'Happy Birthday!',
      gift_sender: 'Jane Doe',
      status: 'paid',
      selectedProduct1 : {
        'product' : '',
        'quantity' : 0
      },
      selectedProduct2 : {
        'product' : '',
        'quantity' : 0
      },
      selectedProduct3 : {
        'product' : '',
        'quantity' : 0
      },
      selectedProduct4 : {
        'product' : '',
        'quantity' : 0
      },
      productOptions : [],
      result : ""
    };
  },
   mounted() {
    this.shop = store.getters.loggedInShop
  },
  methods: {
    submitForm() {
      var transactions = [];
      var receipt_id = Math.floor(Math.random() * 10000000000);
      var price = 0;
      if (this.selectedProduct1.product != '' && this.selectedProduct1.quantity > 0) {
        var transaction1 = this.formatTransaction(this.selectedProduct1, receipt_id);
        price = (price + (this.selectedProduct1.product.price.amount / this.selectedProduct1.product.price.divisor) * this.selectedProduct1.quantity);
        transactions.push(transaction1);
      }
      if (this.selectedProduct2.product != '' && this.selectedProduct2.quantity > 0) {
        var transaction2 = this.formatTransaction(this.selectedProduct2, receipt_id);
        price = (price + (this.selectedProduct2.product.price.amount / this.selectedProduct2.product.price.divisor) * this.selectedProduct2.quantity);
        transactions.push(transaction2);
      }
      if (this.selectedProduct3.product != '' && this.selectedProduct3.quantity > 0) {
        var transaction3 = this.formatTransaction(this.selectedProduct3, receipt_id);
        price = (price + (this.selectedProduct3.product.price.amount / this.selectedProduct3.product.price.divisor) * this.selectedProduct3.quantity);
        transactions.push(transaction3);
      }
      if (this.selectedProduct4.product != '' && this.selectedProduct4.quantity > 0) {
        var transaction4 = this.formatTransaction(this.selectedProduct4, receipt_id);
        price = (price + (this.selectedProduct4.product.price.amount / this.selectedProduct4.product.price.divisor) * this.selectedProduct4.quantity);
        transactions.push(transaction4);
      }
      price = price * 100;
      // Handle form submission
      const formData = {
            'receipt_id':receipt_id,
            'receipt_type':0,
            'seller_user_id':181647289,
            'seller_email':"dpl@dpl.company",
            'buyer_user_id':39465590,
            'buyer_email':this.buyer_email,
            'name':this.name,
            'first_line':this.first_line,
            'second_line':this.second_line,
            'city':this.city,
            'state':this.state,
            'zip':this.zip,
            'status':this.status,
            'formatted_address': this.name + "\n" + this.first_line + "\n" + this.city + ","+this.state+" " + this.zip +"\n"+ this.country_iso,
            'country_iso': this.country_iso,
            'payment_method':this.payment_method,
            'payment_email':this.payment_email,
            'message_from_payment':this.message_from_payment,
            'message_from_seller':this.message_from_seller,
            'message_from_buyer':this.message_from_buyer,
            'is_shipped':this.is_shipped,
            'is_paid':this.is_paid,
            'create_timestamp':Math.floor(Date.now() / 1000),
            'created_timestamp':Math.floor(Date.now() / 1000),
            'update_timestamp':Math.floor(Date.now() / 1000),
            'updated_timestamp':Math.floor(Date.now() / 1000),
            'is_gift':this.is_gift,
            'gift_message':this.gift_message,
            'gift_sender':this.gift_sender,
            'grandtotal':{
              'amount' : price,
              'divisor' : 100,
              'currency_code' : "USD"
            },
            'subtotal':{
              'amount' : price,
              'divisor' : 100,
              'currency_code' : "USD"
            },
            'total_price':{
              'amount' : price,
              'divisor' : 100,
              'currency_code' : "USD"
            },
            'total_shipping_cost':{
              'amount' : 0,
              'divisor' : 100,
              'currency_code' : "USD"
            },
            'total_tax_cost':{
              'amount' : 0,
              'divisor' : 100,
              'currency_code' : "USD"
            },
            'total_vat_cost':{
              'amount' : 0,
              'divisor' : 100,
              'currency_code' : "USD"
            },
            'discount_amt':{
              'amount' : 0,
              'divisor' : 100,
              'currency_code' : "USD"
            },
            'gift_wrap_price':{
              'amount' : 0,
              'divisor' : 100,
              'currency_code' : "USD"
            },
            'shipments':[],
            'transactions':transactions,
            'refunds':[],
            'uri':"/shops/18772841/receipts/" + receipt_id
        };
        const jsonPrepare = {
          "count" : 1,
          "results" : [formData]
        }
        this.result = JSON.stringify(jsonPrepare, null, 2);
    },

     async searchProducts(searchTerm) {
      if (searchTerm.length < 3) {
        return;
      }
      try {
        const response = await axios.get(`/admin/shop/${this.shop.id}/etsy-variations`, {
          params: { search: searchTerm },
        });
        this.productOptions = response.data.products;
      } catch (error) {
        console.error('Error fetching products:', error);
      }
    },

    formatTransaction(selectedProduct, receipt_id) {
      const currentTime = Math.floor(Date.now() / 1000);

          return {
            'transaction_id': Math.floor(Math.random() * 10000000000),
            'title': selectedProduct.product.title,
            'description': "",
            'seller_user_id': 181647289,
            'buyer_user_id': 39465590,
            'create_timestamp': currentTime,
            'created_timestamp': currentTime,
            'paid_timestamp': currentTime,
            'shipped_timestamp': currentTime,
            'quantity': selectedProduct.quantity,
            'listing_image_id': 6014142094,
            'receipt_id': receipt_id,
            'is_digital': false,
            'file_data': "",
            'listing_id': selectedProduct.product.listing_id,
            'sku': selectedProduct.product.sku,
            'product_id': 17412655865,
            'transaction_type': "listing",
            'price': selectedProduct.product.price,
            'shipping_cost': {
              'amount': 0,
              'divisor': 100,
              'currency_code': "USD"
            },
            'variations': selectedProduct.product.variations,
            'product_data': selectedProduct.product.product_data,
            'shipping_profile_id': 174481288654,
            'min_processing_days': 1,
            'max_processing_days': 2,
            'shipping_method': null,
            'shipping_upgrade': null,
            'expected_ship_date': 1729530140,
            'buyer_coupon': 0,
            'shop_coupon': 0
        }
    }
  }
};
</script>

<style scoped>
.order-mock-form {
  max-width: '100%';
  margin: 0 auto;
  padding: 20px;
  border: 1px solid #ccc;
  border-radius: 4px;
}

.form-row {
  display: flex;
  justify-content: space-between;
}

.form-group {
  flex: 1;
  margin-bottom: 15px;
  margin-right: 10px;
}

.form-group:last-child {
  margin-right: 0;
}

.form-group label {
  display: block;
  margin-bottom: 5px;
}

.form-group input,
.form-group select {
  width: 100%;
  padding: 8px;
  box-sizing: border-box;
}

button {
  padding: 10px 15px;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

button:hover {
  background-color: #0056b3;
}
</style>
